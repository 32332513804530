<template>
  <div class="flex flex-row gap-1.5 sm:gap-2 planning-buttons">
    <button
      class="btn icon"
      :class="{
        'bg-purple-400 text-white': !shift.start && !shift.einde,
        'bg-red-400 text-white': shift.start && !shift.einde,
        'bg-teal-300 text-white': shift.start && shift.einde,
      }"
      @click="openFixedModal('FORM_SHIFT', { shift, planning_id: ritten_chauffeurs?.id })"
    >
      <i class="fas fa-clock"></i>
    </button>
    <router-link
      target="_blank"
      :to="`/sign/contract/${rit.id}`"
      class="btn icon"
      :class="{ 'disabled allow-click': rit.is_getekend, 'yellow': !rit.is_getekend }"
    >
      <i class="fas fa-file-signature"></i>
    </router-link>
    <UIButtonRitBetaling
      class="icon"
      :rit="rit"
      :disabled="bedragTeOntvangen === 0"
      is-in-wagen
    />
    <button class="btn green-dark icon" @click="openFixedModal('FORM_RIT_FEEDBACK', { rit, is_retour: ritten_chauffeurs.is_retour })">
      <!-- <i class="fas fa-comment-medical"></i> -->
      <i class="fas fa-comment-alt"></i>
    </button>
    <!--
    <button class="btn indigo icon" :disabled="!wagen" @click="openFixedModal('FORM_KILOMETERSTAND', { wagen })">
      <i class="fas fa-car"></i>
    </button>
    -->
    <button v-if="!rit.is_retour" class="btn orange icon" @click="openFixedModal('FORM_TERUGRIT_AANVRAGEN', { rit })">
      <i class="fas fa-registered"></i>
    </button>
    <router-link target="_blank" :to="`/chat/${rit.id}-${new Date(rit.creation_date).getTime()}`" class="btn indigo icon">
      <i class="fas fa-comments"></i>
    </router-link>
    <router-link :to="`/ritten/info/${rit.id}?deel=${ritten_chauffeurs.is_retour ? 'retour' : 'heen'}`" class="btn blue icon">
      <i class="fas fa-info-circle"></i>
    </router-link>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'

import useBedragTeOntvangen from './utils/useBedragTeOntvangen'

import UIButtonRitBetaling from '@/components/UI/Button/RitBetaling.vue'

import useModal from '@/hooks/useModal'

const props = defineProps({
  rit: Object,
  ritten_chauffeurs: Object,
  wagen: Object,
  shift: Object,
})

const [openFixedModal] = useModal({ fixed: true })

const bedragTeOntvangen = useBedragTeOntvangen({ rit: props.rit, ritten_chauffeurs: props.ritten_chauffeurs })
</script>

<style lang="scss" scoped>
.planning-buttons .btn {
  height: 2.3rem;
  width: 2.3rem;
}
</style>
