<template>
  <button
    v-if="rit"
    class="btn green"
    :disabled="isDisabled"
    @click="openFixedModal('FORM_RIT_BETALING', { rit, user_id, isInWagen, callback: () => emit('refresh') })"
  >
    <i :class="{ 'fas fa-euro-sign': !isDisabled, 'fab fa-creative-commons-nc-eu text-lg': isDisabled }"></i>
    <slot></slot>
  </button>
</template>

<script setup>
import { computed, defineProps } from 'vue'

import useModal from '@/hooks/useModal'

const props = defineProps({
  rit: Object,
  user_id: Number,
  disabled: Boolean,
  isInWagen: Boolean,
})

const emit = defineEmits(['refresh'])
const [openFixedModal] = useModal({ fixed: true })

const isDisabled = computed(() => props.disabled || props.rit.bedrag_open_saldo <= 0)
</script>
